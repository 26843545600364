import DiceFmLogo from '@/components/icons/diceFmLogo';
import ResidentAdvisorLogo from '@/components/icons/residentAdvisorLogo';
import { IconProps } from '@/components/icons/types';
import FacebookLogo from '@/components/icons/facebookLogo';
import EventbriteLogo from '@/components/icons/eventbriteLogo';
import ShotgunLogo from '@/components/icons/shotgunLogo';

const TECHNOLOGY_LOGOS: Record<string, React.FC<IconProps>> = {
  ra: ResidentAdvisorLogo,
  dice: DiceFmLogo,
  fb: FacebookLogo,
  eventbrite: EventbriteLogo,
  shotgun: ShotgunLogo,
};
const TECHNOLOGY_NAMES: Record<string, string> = {
  ra: 'Resident Advisor',
  dice: 'Dice.fm',
  fb: 'Facebook',
  eventbrite: 'Eventbrite',
  shotgun: 'Shotgun',
};

interface TechnologyLinksProps {
  className?: string;
  urlData: Array<{
    technology: string;
    url: string;
    id: string;
  }>;
}

export default function TechnologyLinks({
  className,
  urlData,
}: TechnologyLinksProps) {
  // Sort the technology URLs by the order of the TECHNOLOGY_NAMES object.
  const technologyUrls = Object.keys(TECHNOLOGY_NAMES).flatMap(
    (technologyName) => {
      const urlObj = urlData.find(
        (urlObj) => urlObj.technology === technologyName,
      );
      return urlObj ? [urlObj] : [];
    },
  );

  if (technologyUrls.length === 0) {
    return null;
  }

  return (
    <div className={`technology-links ${className}`}>
      {technologyUrls.map(({ technology, url, id }) => {
        const Logo = TECHNOLOGY_LOGOS[technology];
        const name = TECHNOLOGY_NAMES[technology];

        if (!Logo) {
          return null;
        }

        return (
          <a
            key={id}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            title={`View ${name} event page`}
            className="technology-link"
          >
            <Logo />
          </a>
        );
      })}
    </div>
  );
}
