import { IconProps } from '@/components/icons/types';

const ShotgunLogo = (props: IconProps) => (
  <svg viewBox="0 0 285 417" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M195.126 176.338 278.52 56.324a32.791 32.791 0 0 0-.005-37.46C270.36 7.132 256.946.132 242.62.123L41.759 0C27.498-.005 14.143 6.981 6.046 18.68a32.872 32.872 0 0 0-.005 37.392l213.701 309.192-155.235-.11 54.386-78.41-30.974-44.753L5.867 360.3a32.884 32.884 0 0 0-.051 37.42c8.078 11.725 21.438 18.734 35.708 18.743l200.94.115c14.293.009 27.689-6.968 35.836-18.68a32.82 32.82 0 0 0 .06-37.424L64.687 51.318l155.216.106-55.733 80.156 30.956 44.758Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ShotgunLogo;
