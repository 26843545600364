import { defaultTo } from 'lodash-es';

import type { Event } from '@/types';
import { useSite } from '@/contexts/siteContext';
import { THEME_NAMES } from '@/constants';
import { getThemeValue } from '@/lib/utils';

interface EventActionButtonsProps {
  className?: string;
  event: Event;
}

const themeDefaultButtonText = {
  [THEME_NAMES.DC]: 'Get Tickets',
  [THEME_NAMES.FLORIDA]: 'Get Tickets »',
  default: 'Get Tickets',
};

const themeDefaultButtonTextReservation = {
  [THEME_NAMES.DC]: 'Get Reservation',
  [THEME_NAMES.FLORIDA]: 'Get Reservation »',
  default: 'Get Reservation',
};

const EventActionButtons = ({ className, event }: EventActionButtonsProps) => {
  const { theme_name } = useSite();
  const buyNowDefaultText = getThemeValue(theme_name, themeDefaultButtonText);
  const reservationText = getThemeValue(
    theme_name,
    themeDefaultButtonTextReservation,
  );

  return (
    <div className={className}>
      {event.url_tickets && (
        <a
          className="btn btn-buy-now"
          href={event.url_tickets}
          target="_blank"
          rel="noopener"
        >
          {defaultTo(event.message, buyNowDefaultText)}
        </a>
      )}
      {event.url_reservations && (
        <a
          className="btn btn-reservation mt-4"
          href={event.url_reservations}
          target="_blank"
          rel="noopener"
        >
          {reservationText}
        </a>
      )}
    </div>
  );
};

export default EventActionButtons;
