import { IconProps } from '@/components/icons/types';

const EventbriteLogo = (props: IconProps) => (
  <svg aria-label="Eventbrite Logo" viewBox="0 0 100 98" {...props}>
    <title>Eventbrite</title>
    <g fillRule="evenodd" fill="currentColor">
      <path d="M44.053 23.401c10.985-2.481 21.946 2.016 28.049 10.52L23.556 44.89C25.23 34.627 33.068 25.882 44.053 23.4zm28.32 40.35c-3.67 5.322-9.329 9.35-16.227 10.908-11.057 2.498-22.09-2.076-28.169-10.69l48.698-11.002 7.931-1.792 15.165-3.426a47.9 47.9 0 00-1.263-9.656C92.336 11.931 65.658-4.382 38.923 1.658 12.188 7.698-4.482 33.804 1.691 59.967c6.172 26.162 32.85 42.475 59.585 36.435 15.753-3.559 28.007-14.085 34.156-27.484l-23.059-5.167z"></path>
    </g>
  </svg>
);

export default EventbriteLogo;
